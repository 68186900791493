// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCpSJUl7t664qc7aLXNWSduwSYVTeebkfM",
  authDomain: "boilercalc-999f1.firebaseapp.com",
  projectId: "boilercalc-999f1",
  storageBucket: "boilercalc-999f1.appspot.com",
  messagingSenderId: "448357153146",
  appId: "1:448357153146:web:6f26b0d6be8ed2f688fec3",
  measurementId: "G-XM6R69QPCS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };